
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindTransactionalActivityById } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindTransactionalActivityById';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';
import { SaveHouseBillOfLanding } from '@/wms/application/houseBillOfLading/create/SaveHouseBillOfLanding';
import { FindHousesByOperation } from '@/wms/application/houseBillOfLading/search/FindHousesByOperation';
import { HouseBillOfLading } from '@/wms/domain/houseBillOfLading/HouseBillOfLading';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import HouseBillOfLandingAddForm from '@/wms/infrastructure/ui/billOfLanding/HouseBillOfLandingAddForm .vue';
import html2pdf from 'html2pdf.js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FooterModal from './FooterModals/FooterModal.vue';
import HeaderModal from './HeaderModals/HeaderModal.vue';

//Modal C12: Add House Bill of Landing
@Component({
  name: 'ModalC12',
  components: { HeaderModal, FooterModal, HouseBillOfLandingAddForm }
})
export default class ModalC12 extends Vue {
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_BY_ID)
  private readonly findActivityById!: FindTransactionalActivityById;
  @Inject(TYPES.HOUSE_BILL_OF_LANDING_FIND_BY_OPERATION)
  private readonly findHouses!: FindHousesByOperation;
  @Inject(TYPES.HOUSE_BILL_OF_LADING_SAVE)
  private readonly saveHouses!: SaveHouseBillOfLanding;

  @Prop() isLoading!: boolean;
  @Prop() activityData!: ActivityTransactionalForTraceability;
  @Prop() eventData!: TransactionalEventForTraceability;
  @Prop({ type: Function, required: true }) saveProcess!: (activity: ActivityTransactional) => Promise<void>;
  @Prop() typeOperation!: string;
  @Prop() typeTransport!: string;
  @Prop() master!: PreRegisterForTraceabilityPanel;
  @Prop({ type: Function }) searchActivities!: () => Promise<void>;

  modalActivityData: ActivityTransactional = new ActivityTransactional();
  housesList: HouseBillOfLading[] = [];

  get clientsByBulks() {
    // return [...this.master.bulks.map(bulk => bulk.owner)];
    return [];
  }

  get eventExecuted() {
    return this.eventData.state === 'EXECUTED';
  }

  get modalTitle() {
    return `${this.eventData.description} - ${this.$t('switchs.' + this.activityData?.sw)} - ${
      this.activityData.keyModal
    }`;
  }

  get localIsLoading() {
    return this.isLoading;
  }

  set localIsLoading(value) {
    this.$emit('update:isLoading', value);
  }

  clearBl(): void {
    // this.masterBl = new BillOfLanding();
  }

  exportToPDF(id: string) {
    const config = {
      margin: 0.1,
      pagebreak: { mode: 'avoid-all' },
      filename: id,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'legal', orientation: 'p' }
    };
    html2pdf(document.getElementById(id), config);
  }

  addHouse() {
    if (!this.housesList) this.housesList = new Array<HouseBillOfLading>();

    const house = new HouseBillOfLading();
    house.masterBillOfLanding = this.master.masterBl;

    this.housesList?.push(house);
  }

  removeHouse(event: Event, index: number) {
    event.preventDefault();
    this.housesList.splice(index, 1);
  }

  async saveBL() {
    try {
      if (!this.housesList) return;

      this.localIsLoading = true;

      await this.saveHouses.execute(this.housesList);
      await this.saveProcess(this.modalActivityData);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  async findActivityData() {
    try {
      if (!this.activityData) return;

      this.localIsLoading = true;

      this.modalActivityData = await this.findActivityById.execute({
        activityId: this.activityData?.activityId,
        eventId: this.eventData.eventId,
        preRegisterNumber: this.master.number,
        preRegisterType: this.master.type
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  async findHousesList() {
    try {
      if (!this.master) return;

      this.localIsLoading = true;

      this.housesList = await this.findHouses.execute({
        number: this.master.number,
        type: this.master.type
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  async findData() {
    await this.findHousesList();
    await this.findActivityData();
  }

  handleOpenModal(serial: string) {
    this.$bvModal.show(`houseTransportDocument-${serial}`);
  }
}
